export const Stat = ({
    icon,
    data,
    title,
  }: {
    icon: string
    data: string
    title: string
  }) => {
    return (
      <div className='flex items-start space-x-1 mx-6'>
        <img src={icon} alt='group' className='w-[40px] h-[40px]' />
        <div className='flex flex-col space-y-2 mt-2'>
          <p className='text-[14px] font-weigth-normal font-semibold text-slate-gray leading-6'>
            {title}
          </p>
          <p className='text-[36px] font-weigth-normal font-semibold text-slate-gray leading-6'>
            {data}
          </p>
        </div>
      </div>
    )
  }
  