import { Theme } from '@mui/material/styles'
//
import Button from './Button'
import Input from './Input'
import Accordion from './Accordion'
import Menu from './Menu'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import DataGrid from './DataGrid'

// ----------------------------------------------------------------------

export default function ComponentsOverrides (theme: Theme) {
  return Object.assign(
    Accordion(),
    Button(theme),
    Checkbox(),
    DatePicker(theme),
    Input(theme),
    Menu(theme),
    DataGrid()
  )
}
