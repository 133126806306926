import Avatar from '../assets/avatar.svg'
import Button from '@mui/material/Button'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Image from '../assets/icon-image.png'
import { format } from 'date-fns'
import { useState } from 'react'
import { Post as PostType } from '../constants/types'

export const Post = ({
  post,
  setOpen,
  setPostIds,
  setOpenDeleteModal,
}: {
  post: PostType
  setOpen: (b: boolean) => void
  setOpenDeleteModal: (b: boolean) => void
  setPostIds: (postIds: number[]) => void
}) => {
  const [expanded, setEspanded] = useState<boolean>(false)

  return (
    <div className={`flex flex-row w-full relative`}>
      <div
        className={`w-[600px] bg-white rounded-lg shadow-lg relative overflow-hidden ${expanded ? 'h-full' : 'h-[160px]'
          }`}
      >
        <div className='flex flex-row space-x-3 p-3'>
          <div className='flex w-[40px] h-[40px] aspect-square'>
            <img
              src={post.user.profilePicture || Avatar}
              alt='avatar'
              className='h-auto max-w-full rounded-full shadow-lg object-cover border-solid border-2 border-mint-turquoise'
            />
          </div>
          <div className='flex flex-col'>
            <p className='text-[14px] font-valera-round text-charcoal-black font-stretch-normal font-bold leading-1.43 tracking-normal'>
              {post.user.userName}
            </p>
            <p className='text-[12px] font-open-sans text-pewter-gray font-light tracking-normal '>
              {post.user.country.name}
            </p>
          </div>
          <p className=' text-pewter-gray font-open-sans'>
            @{post.user.userName} •{' '}
            {format(new Date(post.createdAt), 'MM/dd/yy, HH:mm:ss')}
          </p>
        </div>
        <div
          className={`w-[600px] relative overflow-hidden ${expanded ? 'h-auto' : 'h-[80px]'
            }`}
        >
          {post.medias.length > 0 && post.type === 'IMAGE' && (
            <div className='flex flex-col items-center'>
              <img src={post.medias[0].url} alt='medias'></img>
            </div>
          )}

          {post.medias.length > 0 && post.type === 'VIDEO' && (
            <div className='flex flex-col items-center'>
              <video src={post.medias[0].url} muted controls className='w-[539px]'></video>
            </div>
          )}

          {post.content && <div
            id='scroll-text'
            className='w-[539px] h-[65px] overflow-hidden px-3'
          >
            <p>{post.content}</p>
          </div>}
          {post.poll && post.poll.options.length && <div className='flex flex-col w-[539px] px-8 gap-2 py-4'>
            {post.poll.options.map((option, i) => {

              return <div key={i} className='flex gap-2 items-center'>
                {!option.pictureUrl ? <div className='min-w-[96px] h-[96px] bg-[#EEE] flex items-center justify-center'>
                  <img src={Image} alt={option.text} className='w-14 h-14' />
                </div> : <img src={option.pictureUrl} alt={option.text} className='w-24 h-24 bg-[#EEE]' />
                }
                <div className='flex border-[3px] border-solid border-[#70bda1] w-full h-[36px] items-center rounded-lg relative overflow-hidden bg-[#EEE]'>
                  <p className='pl-4 z-10 font-valera-round'>{option.text}</p>
                  <div className={`flex absolute h-[36px] bg-[#cae8d9]`} style={{ width: `${option.percentage}%` }}></div>
                </div>
              </div>
            })}
          </div>}

          <div className='w-full h-[16px] absolute bg-gradient-to-b from-transparent to-white bottom-0'></div>
        </div>
        <div
          className='flex flex-col items-end absolute bottom-2 right-3 text-pewter-gray'
          onClick={() => setEspanded(!expanded)}
        >
          {expanded ? (
            <>
              <KeyboardArrowDownIcon fontSize='small' />
              <KeyboardArrowUpIcon fontSize='small' />
            </>
          ) : (
            <>
              <KeyboardArrowUpIcon fontSize='small' />
              <KeyboardArrowDownIcon fontSize='small' />
            </>
          )}
        </div>
        <div
          style={{
            clipPath: ' polygon(18% 0, 100% 0%, 100% 100%, 18% 100%, 0% 50%)',
          }}
          className='flex w-[80px] h-[40px] bg-raspberry-red absolute rounded-r-lg top-2 -right-1 text-white items-center space-x-2 pl-5 font-[14px] font-open-sans'
        >
          <ErrorOutlineIcon /> <p>{post.reportsReceived}</p>
        </div>
      </div>
      <div className='flex flex-col space-y-3 w-[160px] ml-6'>
        <Button
          variant='outlined'
          onClick={() => {
            setPostIds([post.id])
            setOpenDeleteModal(true)
          }}
        >
          Delete Post
        </Button>
        <Button
          variant='outlined'
          onClick={() => {
            setPostIds([post.id])
            setOpen(true)
          }}
        >
          Clear Report
        </Button>
      </div>
    </div>
  )
}
