import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'zustand'
import { authStore } from '../../stores/auth'
import { logout } from '../../services/auth'

export const Logout = () => {
  const { setLogout, user } = useStore(authStore)
  const navigate = useNavigate()

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await logout()
      } catch (error) {
        console.log(error)
      }
      setLogout()
      navigate('/login', { replace: true })
    }

    if (user) {
      logoutUser()
    }
  }, [navigate, setLogout, user])

  return <></>
}
