import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface PaginationState {
  page: number
  count: number
  pageLimit: number
}

export interface PaginationStoreState extends PaginationState {
  clearPagination: () => void
  setPage: (page: number) => void
  setCount: (count: number) => void
  setPageLimit: (count: number) => void
}

const initState: PaginationState = {
  page: 0,
  count: 0,
  pageLimit: 10,
}

export const usePaginationStore = create<PaginationStoreState>()(
  devtools((set) => ({
    ...initState,
    clearPagination: () => set(() => initState),
    setPage: (page: number) => set((state) => ({ ...state, page })),
    setCount: (count: number) => set((state) => ({ ...state, count })),
    setPageLimit: (pageLimit: number) => set((state) => ({ ...state, pageLimit })),
  }))
)
