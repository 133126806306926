import { ConfirmModal, CustomDrawer, Post } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Pagination } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getReportedPosts, updateStatus } from '../../services/users';
import { Post as PostType } from '../../constants/types';
import { clearReports, deletePost } from '../../services/moderations';
import { getCountForPagination } from '../../utils/helpers';

const pageLimit = 4;

export const ReportedPosts = () => {
  const { id } = useParams();
  const [posts, setPosts] = useState<PostType[]>([]);
  const [postIds, setPostIds] = useState<number[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [banUser, setBanUser] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const apiCall = useCallback(
    async (id: number) => {
      try {
        const response = await getReportedPosts(id, page, pageLimit);

        if (response.statusCode === 200) {
          if (response.data.length === 0) navigate('/dashboard');
          setPosts(response.data);
          setCount(response.meta.pagination.count);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [navigate, page],
  );

  useEffect(() => {
    if (id) apiCall(parseInt(id));
  }, [id, apiCall]);

  const handleClearReports = async (postIds: number[]) => {
    try {
      const response = await clearReports(postIds);
      if (response.statusCode === 200) {
        setPostIds([]);
        if (id) apiCall(parseInt(id));
        alert('Reports cleared');
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmClearReports = () => {
    handleClearReports(postIds);
  };

  const handleDeletePost = async (postId: number) => {
    try {
      const response = await deletePost(postId);
      if (response.statusCode === 200) {
        if (id) apiCall(parseInt(id));
        alert('Post deleted');
      }
      setOpenDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeletePost = () => {
    handleDeletePost(postIds[0]);
  };

  const confirmBanUser = async () => {
    if (id) {
      const userId = parseInt(id);
      try {
        const response = await updateStatus({
          ids: [userId],
          status: 'Banned',
        });
        if (response.statusCode === 200) {
          alert('User banned');
          navigate('/dashboard');
        }
        setOpen(false);
        setBanUser(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className='flex w-full h-full min-h-screen bg-background'>
      <CustomDrawer />
      <div className='w-full h-full pl-[35px] pt-[30px] mr-8'>
        <div className='flex flex-row justify-between items-center h-[40px]'>
          <p className='text-[24px] font-weigth-normal text-[#12314D] leading-10'>
            User Reports
          </p>

          <div className='mx-2 space-x-2'>
            <Button
              color='primary'
              variant='outlined'
              onClick={() => {
                setOpen(true);
                setPostIds(posts.map((post: PostType) => post.id));
              }}
            >
              Clear All Reports
            </Button>
            <Button
              color='error'
              variant='outlined'
              onClick={() => {
                setOpen(true);
                setBanUser(true);
              }}
            >
              Suspend Account
            </Button>
          </div>
        </div>
        <div className='flex flex-col w-full h-[calc(100vh_-_90px)] p-6 mt-6 bg-cloud-white space-y-6'>
          <div className='flex flex-col w-full h-full gap-4'>
            {posts.length > 0 &&
              posts.map((post: PostType) => (
                <Post
                  key={post.id}
                  post={post}
                  setOpen={setOpen}
                  setPostIds={setPostIds}
                  setOpenDeleteModal={setOpenDeleteModal}
                />
              ))}
          </div>
          <div className='flex w-full items-center justify-center'>
            <Pagination
              count={getCountForPagination(count, pageLimit)}
              showFirstButton
              showLastButton
              page={page}
              onChange={handleChange}
              color='primary'
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        open={open}
        handleClose={() => {
          setOpen(false);
          banUser && setBanUser(false);
        }}
        text={
          banUser
            ? 'Are you sure you want to ban the user?'
            : 'Are you sure you want to clear the reports?'
        }
        onConfirm={banUser ? confirmBanUser : confirmClearReports}
      />
      <ConfirmModal
        open={openDeleteModal}
        handleClose={() => {
          setOpenDeleteModal(false);
        }}
        text={`Are you sure you want to delete the post?`}
        onConfirm={confirmDeletePost}
      />
    </div>
  );
};
