import { Button, Menu, MenuItem } from '@mui/material'

import { useState, MouseEvent } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const CustomMenu = ({
  title,
  selected,
  statusType,
  onSelect,
}: {
  title: string
  selected: boolean
  statusType: string
  onSelect: (s: string) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (value: string) => {
    setAnchorEl(null)
    onSelect(value)
  }

  return (
    <div
      className={`border-solid border-2 border-${selected ? 'white' : '#ecefee'
        } rounded-[10px]`}
    >
      <Button variant='contained' disabled={!selected} onClick={handleClick}>
        {title} <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id='basic-menu'
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ width: 120, maxWidth: '100%' }}
      >
        <div className='w-[120px]'>
          <MenuItem onClick={() => handleClose('Active')}>Active</MenuItem>
          {statusType === 'account' && (
            <>
              <MenuItem onClick={() => handleClose('Banned')}>Banned</MenuItem>
              <MenuItem onClick={() => handleClose('Deleted')}>
                Deleted
              </MenuItem>
            </>
          )}
        </div>
      </Menu>
    </div>
  )
}
