import { Typography } from '@mui/material'
import {
  NewPasswordForm,
  LoginForm,
  ForgotPasswordForm,
  ValidateCodeForm,
  ChangePasswordForm
} from '../components'

export const WelcomeScreen = ({ form }: { form?: string }) => {
  return (
    <div className='flex w-full h-[100vh] bg-red-300'>
      <div className='md:flex flex-col hidden lg:w-[60%] w-full h-full bg-[#12314d] items-center justify-center gap-8'>
        <Typography
          fontFamily={'Varela Round, sans-serif'}
          fontSize={'54px'}
          letterSpacing={'-0.51px'}
          color={'#FFF'}
        >
          Qclap
        </Typography>
        <Typography
          fontFamily={'Open Sans, sans-serif'}
          fontSize={'24px'}
          color={'#FFF'}
        >
          Explore the world.
        </Typography>
        <Typography
          fontFamily={'Open Sans, sans-serif'}
          fontSize={'24px'}
          color={'#FFF'}
          textAlign={'center'}
          className='max-w-[520px]'
        >
          Make connections with people around the world or around the corner.
          With Qclap, you’re in full control of what you see.
        </Typography>
      </div>
      <div className='flex lg:w-[40%] w-full h-full bg-[#f5f7f6] items-center justify-center'>
        {form === 'login' && <LoginForm />}
        {form === 'forgotPassword' && <ForgotPasswordForm />}
        {form === 'validateCode' && <ValidateCodeForm />}
        {form === 'newPassword' && <NewPasswordForm />}
        {form === 'changePassword' && <ChangePasswordForm />}
      </div>
    </div>
  )
}
