import { Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { iniciatePasswordRecovery } from '../../services/auth'
import { useNavigate } from 'react-router-dom'

export const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, 'Invalid email')
      .required('Email must be written down'),
  })

  const initialValues = {
    email: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        const { email } = values
        const response = await iniciatePasswordRecovery(email)

        if (response.data.statusCode === 200) {
          navigate('/validate-code', { state: { email } })
        }

        if (response.data.statusCode === 500) {
          setError(response.data.message)
        }
      } catch (error) {
        setError('Incorrect email, Please try again.')
      }
    },
  })

  useEffect(() => {
    setError('')
  }, [values])

  return (
    <form
      className='flex flex-col w-full gap-6 2xl:px-36 md:px-8'
      onSubmit={handleSubmit}
    >
      <div className='flex w-full'>
        <Typography
          fontFamily={'Varela Round, sans-serif'}
          fontSize={'34px'}
          letterSpacing={'-0.51px'}
          color={'#000'}
        >
          Forgot Password
        </Typography>
      </div>
      {error !== '' && (
        <div className='flex w-[full] justify-center bg-[#d7263d] rounded-md py-3 px-2'>
          <Typography
            sx={{
              width: '432px',
              height: '22px',
              fontFamily: 'OpenSans',
              fontSize: '16px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: '#fff',
            }}
          >
            {error}
          </Typography>
        </div>
      )}
      <div className='flex w-full justify-center'>
        <TextField
          id='email'
          fullWidth
          label='Email'
          type='text'
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.email && touched.email) as boolean}
          helperText={
            !!errors.email &&
            touched.email !== undefined &&
            (errors.email as string)
          }
        />
      </div>

      <div className='flex w-full justify-center '>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!isValid}
          fullWidth
        >
          Send email
        </Button>
      </div>
      <div className='flex w-full justify-center gap-3'>
        <Typography
          fontFamily={'Open Sans, sans-serif'}
          fontSize={'16px'}
          color={'#45ae9f'}
          className='cursor-pointer'
          onClick={() => navigate('/')}
        >
          Return to login
        </Typography>
      </div>
    </form>
  )
}
