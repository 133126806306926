import { AxiosResponse } from 'axios'
import { http } from '../utils/http'
import { GenericResponse } from '../constants/interfaces'

const apiUrl = process.env.REACT_APP_API_URL

export const clearReports = async (
  postIds: number[],
): Promise<GenericResponse> => {
  try {
    const { data }: AxiosResponse<any> = await http.post(
      `${apiUrl}/api/moderations/clear-many`,
      {
        postIds,
      },
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const deletePost = async (postId: number): Promise<GenericResponse> => {
  try {
    const { data }: AxiosResponse<any> = await http.delete(
      `${apiUrl}/api/moderations/${postId}`,
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}
