import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

const columns: GridColDef[] = [
  {
    field: 'hashtag',
    headerName: 'Hashtag',
    flex: 1,
    editable: false,
  },
  {
    field: 'views',
    headerName: 'Views',
    type: 'number',
    flex: 1,
    editable: false,
  },
  {
    field: 'percentage',
    headerName: 'Percentage',
    type: 'number',
    flex: 1,
    editable: false,
    renderCell: (cellValues) => {
      return <p>{cellValues.row.percentage}%</p>
    },
  },
]

const rows = [
  { id: 1, hashtag: '#food', views: 1500, percentage: 15.5 },
  { id: 2, hashtag: '#travel', views: 1200, percentage: 12.3 },
  { id: 3, hashtag: '#fitness', views: 800, percentage: 8.2 },
  { id: 4, hashtag: '#tech', views: 2000, percentage: 20.5 },
  { id: 5, hashtag: '#fashion', views: 1800, percentage: 18.2 },
  { id: 6, hashtag: '#music', views: 1000, percentage: 10.2 },
  { id: 7, hashtag: '#gaming', views: 2500, percentage: 25.7 },
  { id: 8, hashtag: '#movies', views: 3000, percentage: 30.8 },
  { id: 9, hashtag: '#books', views: 500, percentage: 5.1 },
  { id: 10, hashtag: '#art', views: 700, percentage: 7.2 },
  { id: 11, hashtag: '#science', views: 1200, percentage: 12.3 },
  { id: 12, hashtag: '#nature', views: 900, percentage: 9.2 },
  { id: 13, hashtag: '#photography', views: 1600, percentage: 16.4 },
  { id: 14, hashtag: '#home', views: 1100, percentage: 11.2 },
  { id: 15, hashtag: '#pets', views: 600, percentage: 6.1 },
]

export const SimpleTable = () => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        disableColumnMenu
      />
    </Box>
  )
}
