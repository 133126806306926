import './App.css'
import { WelcomeScreen, Dashboard, UserInfo, ReportedPosts } from './pages'
import Logout from './pages/Logout'
import { AuthProvider } from './providers/AuthProvider'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <AuthProvider>
              <WelcomeScreen form={'login'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/*'
          element={
            <AuthProvider>
              <WelcomeScreen form={'login'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/login'
          element={
            <AuthProvider>
              <WelcomeScreen form={'login'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/logout'
          element={
            <AuthProvider>
              <Logout />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/forgot-password'
          element={
            <AuthProvider>
              <WelcomeScreen form={'forgotPassword'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/validate-code'
          element={
            <AuthProvider>
              <WelcomeScreen form={'validateCode'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/new-password'
          element={
            <AuthProvider>
              <WelcomeScreen form={'newPassword'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/change-password'
          element={
            <AuthProvider>
              <WelcomeScreen form={'changePassword'} />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='/dashboard'
          element={
            <AuthProvider>
              <Dashboard />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='dashboard/user-info/:id'
          element={
            <AuthProvider>
              <UserInfo />
            </AuthProvider>
          }
        ></Route>
        <Route
          path='dashboard/reported-posts/:id'
          element={
            <AuthProvider>
              <ReportedPosts />
            </AuthProvider>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
