import { alpha, Theme } from '@mui/material/styles'
import { TextFieldProps } from '@mui/material'
// ----------------------------------------------------------------------

const Input = (theme: Theme) => {
  const rootTextFieldStyle = (ownerState: TextFieldProps) => {
    const outlinedVariant = ownerState.variant === 'outlined'

    const defaultStyle = {
      fontFamily: 'Barlow , sans-serif',
      '&:focus': {
        outline: 'none',
      },
      // OUTLINED
      ...(outlinedVariant && {
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.secondary.main,
        },
        '& .MuiInputLabel-shrink': {
          color: theme.palette.secondary.main,
          fontWeight: '700',
          fontSize: '21px',
        },
        '& .MuiSelect-select': {
          color: '#FFF',
          fontWeight: 'normal !important',
          minHeight: '20px',
          display: 'flex',
          alignItems: 'center',
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px transparent inset',
            WebkitTextFillColor: '#A0A0A0 !important',
          },
        },
      }),
    }

    const disabledState = {
      '&.Mui-disabled': {
        background: `${theme.palette.primary.dark}`,
        color: '#FFF',
      },
    }

    return [defaultStyle, disabledState]
  }

  return {
    MuiInputBase: {
      defaultProps: {
        size: 'small',
        autoComplete: 'off',
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontFamily: 'Barlow , sans-serif',
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.01em',
          color: '#38627A',
          '&.Mui-disabled': {
            '& svg': {
              color: theme.palette.text.disabled,
            },
          },
        },
        input: {
          fontFamily: 'Barlow , sans-serif',
          '&::placeholder': {
            opacity: 1,
            color: '#2a616c',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          fontFamily: 'Barlow , sans-serif',
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
          '&:after': {
            borderBottomColor: theme.palette.text.primary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: TextFieldProps }) =>
          rootTextFieldStyle(ownerState),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'Barlow , sans-serif',
          borderRadius: '4px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.secondary.light,
            },
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.error.main,
            },
            '& .MuiInputAdornment-root': {
              color: theme.palette.error.main,
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#FFF',
          '& :hover': {
            borderRadius: '8px',
            backgroundColor: '#FFF',
            border: '1px solid #378685'
          }
        },
        input: {
          fontWeight: '500',
          fontSize: '14px',
          fontFamily: 'Open Sans, sans-serif',
          lineHeight: 1.57,
          color: '#000'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '0px',
        },
        shrink: {
          top: '-2px',
          paddingRight: '6px',
          fontWeight: '600',
          fontSize: '11px',
          fontFamily: 'Open Sans, sans-serif',
          color: '#565b59',
          '&.Mui-focused': {
            color: `${theme.palette.secondary.light} !important`,
          },
          '&.Mui-error': {
            color: `${theme.palette.error.main} !important`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'Barlow , sans-serif',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#F44336',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
        },
      },
    },
  }
}

export default Input
