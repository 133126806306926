import { Divider } from '@mui/material'
import Group from '../../assets/icon-view.png'
import Clap from '../../assets/icon-clap-inactive.png'
import Sharrow from '../../assets/icon-sharrow.png'
import Bookmark from '../../assets/icon-bookmark-inactive.png'
import Chat from '../../assets/icon-chat-small-inactive.png'
import Edit from '../../assets/icon-create.png'
import Image from '../../assets/icon-image.png'
import Audio from '../../assets/icon-audio.png'
import Poll from '../../assets/icon-poll.png'
import Video from '../../assets/icon-video.png'
import { SimpleTable } from '../../components/SimpleTable'
import { Stat } from '../../components'

const stats = [
  {
    icon: Group,
    title: 'Posts Viewed',
    data: '5,234',
  },
  {
    icon: Clap,
    title: 'Claps',
    data: '564',
  },
  {
    icon: Chat,
    title: 'Replies',
    data: '345',
  },
  {
    icon: Sharrow,
    title: 'Shares',
    data: '234',
  },
  {
    icon: Bookmark,
    title: 'Bookmarks',
    data: '123',
  },
]

const viewedStats = [
  {
    icon: Edit,
    title: 'Text Only',
    data: '1,352',
  },
  {
    icon: Image,
    title: 'Image',
    data: '1,234',
  },
  {
    icon: Video,
    title: 'Video',
    data: '1,234',
  },
  {
    icon: Poll,
    title: 'Poll',
    data: '1,234',
  },
  {
    icon: Audio,
    title: 'Audios',
    data: '1,234',
  },
]

export const Creation = ({ userId }: { userId: string | undefined }) => {
  return (
    <div className='w-full pl-[35px] pt-[30px] mr-8 space-y-8'>
      <div>
        <p className='font-valera-round text-[24px] font-weigth-normal text-deep-blue leading-10'>
          Creation
        </p>
        <p className='text-[14px] font-weigth-normal text-slate-gray leading-6'>
          Measures of other users’ engagement with this user’s content.
        </p>
      </div>

      <div className='w-full flex space-x-12'>
        <div className='flex flex-col space-y-2'>
          <p className='text-[14px] font-weigth-normal font-semibold text-slate-gray leading-6'>
            Posts
          </p>
          <p className='text-[36px] font-weigth-normal font-semibold text-slate-gray leading-6'>
            1,234
          </p>
        </div>
      </div>

      <div className='w-full h-[138px] bg-white rounded-md p-4'>
        <p className='text-[14px] font-weigth-normal text-slate-gray leading-6 uppercase'>
          Posts viewed by content type
        </p>
        <div className='w-full grid grid-cols-5'>
          {viewedStats.map((stat) => {
            return <Stat icon={stat.icon} title={stat.title} data={stat.data} />
          })}
        </div>
      </div>

      <div className='w-full h-[138px] bg-white rounded-md p-4'>
        <p className='text-[14px] font-weigth-normal text-slate-gray leading-6'>
          POST ENGAGEMENT BY TYPE
        </p>
        <div className='w-full grid grid-cols-5'>
          {stats.map((stat) => {
            return <Stat icon={stat.icon} title={stat.title} data={stat.data} />
          })}
        </div>
      </div>

      <div className='grid grid-cols-2 gap-8 pb-6'>
        <div>
          <p className='text-[18px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
            Top Hashtags
          </p>
          <SimpleTable />
        </div>
        <div>
          <p className='text-[18px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
            Top Post Topics
          </p>
          <SimpleTable />
        </div>
      </div>
      <Divider />
      <p className='text-[20px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
        Top Engagement
      </p>
      <div className='grid grid-cols-2 gap-8'>
        <div>
          <p className='text-[18px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
            Top Engagement by Hashtag
          </p>
          <SimpleTable />
        </div>
        <div>
          <p className='text-[18px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
            Top Engagement by Topic
          </p>
          <SimpleTable />
        </div>
        <div>
          <p className='text-[18px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
            Top Engagement by People
          </p>
          <SimpleTable />
        </div>
        <div>
          <p className='text-[18px] font-weigth-normal font-semibold text-charcoal-black leading-10 '>
            Top Mentions by People
          </p>
          <SimpleTable />
        </div>
      </div>
    </div>
  )
}
