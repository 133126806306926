import { useLocation, Navigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { authStore } from '../stores/auth';

const isAuthorized = (authRoles: string[], userRole: string) => {
  const isValid = authRoles.some((role) => role === userRole);
  if (!isValid) {
    return false;
  }
  return true;
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { user, recoveryToken } = useStore(authStore);
  const { pathname: location } = useLocation();

  switch (location) {
    case '/forgot-password':
    case '/validate-code':
    case '/logout':
    case '/change-password':
      break;
    case '/':
    case '/login':
      if (user && isAuthorized(['SUPER_ADMIN'], user?.userType)) {
        return <Navigate to='/dashboard' replace />;
      }
      break;
    case '/new-password':
      if (!recoveryToken) {
        return <Navigate to='/' replace />;
      }
      break;
    case '/dashboard':
      if (
        !user ||
        (user?.userType && !isAuthorized(['SUPER_ADMIN'], user?.userType))
      ) {
        return <Navigate to='/' replace />;
      }
      break;
    default:
      if (
        (location.includes('dashboard/reported-posts/') ||
          location.includes('dashboard/user-info/')) &&
        !isAuthorized(['SUPER_ADMIN'], user?.userType as string)
      ) {
        return <Navigate to='/' replace />;
      }
      break;
  }

  return children;
};
