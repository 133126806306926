import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string
  }
  // interface SimplePaletteColorOptions {
  //   lighter: string
  //   darker: string
  // }
  // interface PaletteColor {
  //   lighter: string
  //   darker: string
  // }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#ecefee',
  200: '#F4F6F8',
  300: '#d8dad9',
  400: '#C4CDD5',
  500: '#858c89',
  600: '#637381',
  700: '#565b59',
  800: '#212B36',
  900: '#171a1c'
}

const PRIMARY_LIGHT = {
  light: '#CAE8D9',
  main: '#70bda1',
  dark: '#45ae9f',
  contrastText: '#FFF'
}

const SECONDARY_LIGHT = {
  light: '#378685',
  main: '#2a616c',
  dark: '#12314d',
  contrastText: '#45ae9f'
}

const PRIMARY_DARK = {
  light: '#cae8d9',
  main: '#70bda1',
  dark: '#45ae9f',
  contrastText: '#0d0f10'
}

const SECONDARY_DARK = {
  light: '#378685',
  main: '#2a616c',
  dark: '#45ae9f',
  contrastText: '#45ae9f'
}

const INFO = {
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  contrastText: '#fff'
}

const SUCCESS = {
  light: '#E5F2D3',
  main: 'rgba(102, 187, 106, 1)',
  dark: '#0B8A00',
  contrastText: '#fff'
}

const WARNING = {
  light: '#FFF0E5',
  main: 'rgba(255, 167, 38, 1)',
  dark: '#B76E00',
  contrastText: GREY[800]
}

const ERROR = {
  light: '#eb929e',
  main: '#d7263d',
  dark: '#B71D18',
  contrastText: '#fff'
}

const COMMON = {
  common: { black: '#000', white: '#fff' },
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: '#A0A0A0',
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
}

export default function palette (themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    primary: PRIMARY_LIGHT,
    secondary: SECONDARY_LIGHT,
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500]
    },
    background: {
      paper: '#FAFAFA',
      default: '#FAFAFA',
      neutral: GREY[200]
    },
    action: {
      ...COMMON.action,
      active: GREY[600]
    }
  } as const

  const dark = {
    ...COMMON,
    mode: 'dark',
    primary: PRIMARY_DARK,
    secondary: SECONDARY_DARK,
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600]
    },
    background: {
      paper: '#1b2130',
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16)
    },
    action: {
      ...COMMON.action,
      active: GREY[500]
    }
  } as const

  return themeMode === 'light' ? light : dark
}
