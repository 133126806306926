import { Box } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

export const History = ({ userId }: { userId: string | undefined }) => {
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date, Time',
      flex: 1,
      editable: false,
    },
    {
      field: 'eventType',
      headerName: 'Event Type',
      flex: 1,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'escription',
      flex: 2,
      editable: false,
    },
  ]
  const rows = [
    {
      id: 1,
      date: '2023-11-24 10:00:00',
      eventType: 1,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      id: 2,
      date: '2023-11-25 14:30:00',
      eventType: 2,
      description:
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 3,
      date: '2023-11-26 18:45:00',
      eventType: 3,
      description:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      id: 4,
      date: '2023-11-27 12:15:00',
      eventType: 1,
      description:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      id: 5,
      date: '2023-11-28 16:20:00',
      eventType: 2,
      description:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    {
      id: 6,
      date: '2023-11-29 09:30:00',
      eventType: 3,
      description:
        'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.',
    },
    {
      id: 7,
      date: '2023-11-30 14:45:00',
      eventType: 1,
      description:
        'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.',
    },
    {
      id: 8,
      date: '2023-12-01 11:00:00',
      eventType: 2,
      description: 'Et harum quidem rerum facilis est et expedita distinctio.',
    },
    {
      id: 9,
      date: '2023-12-02 13:20:00',
      eventType: 3,
      description:
        'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.',
    },
    {
      id: 10,
      date: '2023-12-03 15:40:00',
      eventType: 1,
      description:
        'Omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.',
    },
    {
      id: 11,
      date: '2023-12-04 17:00:00',
      eventType: 2,
      description:
        'Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
    },
    {
      id: 12,
      date: '2023-12-05 19:15:00',
      eventType: 3,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      id: 13,
      date: '2023-12-06 10:30:00',
      eventType: 1,
      description:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      id: 14,
      date: '2023-12-07 12:45:00',
      eventType: 2,
      description:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    },
    {
      id: 15,
      date: '2023-12-08 15:00:00',
      eventType: 3,
      description:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
    // Add more rows as needed
  ]
  return (
    <div className='w-full pl-[35px] pt-[30px] mr-8 space-y-8'>
      <p className='font-valera-round text-[24px] font-weigth-normal text-deep-blue leading-10'>
        History
      </p>

      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          disableColumnMenu
          sx={{ backgroundColor: 'white' }}
        />
      </Box>
    </div>
  )
}
