import { ChangeEvent, useState, MouseEvent } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import { visuallyHidden } from '@mui/utils'
import { format } from 'date-fns'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { User } from '../constants/interfaces'
import { TextField } from '@mui/material'
import { CustomSelect, CustomDatePicker, ReportsModal } from '.'
import { getCountries, getStatuses } from '../services/users'
import inputDataStore from '../stores/inputDataStore'
import useFilterStore, { UserFilters } from '../stores/filterStore'
import { usePaginationStore } from '../stores/pagination'

const StatusLabel = ({ label }: { label: string }) => {
  const statusColor =
    label === 'Active'
      ? 'bg-stormy-teal'
      : label === 'Disabled'
        ? 'bg-amber-400'
        : 'bg-raspberry-red'
  return (
    <div className='w-[150px] flex justify-center'>
      <div
        className={`w-[67px] h-[32px] flex justify-center items-center rounded-xl ${statusColor}`}
      >
        <p className=' font-open-sans text-white'>{label}</p>
      </div>
    </div>
  )
}

const ReportReceivedLabel = ({
  userId,
  label,
  navigate
}: {
  userId: number
  label: number
  navigate: NavigateFunction
}) => {
  return (
    <div className='w-[145px] flex justify-center'>
      <div
        className={`w-[45px] h-[32px] flex justify-center items-center rounded-xl ${label > 0 ? 'bg-raspberry-red' : 'bg-cloud-white default'
          }`}
        onClick={
          label > 0
            ? (e) => {
              e.stopPropagation()
              navigate(`/dashboard/reported-posts/${userId}`)
            }
            : () => undefined
        }
      >
        <p
          className={`font-open-sans text-${label > 0 ? 'cloud-white' : 'stormy-teal'
            }`}
        >
          {label}
        </p>
      </div>
    </div>
  )
}

const ReportMadeLabel = ({ label }: { label: number }) => {
  return (
    <div className='w-[125px] flex justify-center'>
      <div className='w-[45px] h-[32px] flex justify-center items-center rounded-xl bg-stormy-teal'>
        <p className='font-open-sans text-cloud-white'>{label}</p>
      </div>
    </div>
  )
}


type Order = 'asc' | 'desc'

interface HeadCell {
  id: keyof User
  numeric: boolean
  disablePadding: boolean
  label: string
  width?: number
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Account ID',
    width: 160,
  },
  {
    id: 'userName',
    numeric: false,
    disablePadding: true,
    label: 'Username',
  },
  {
    id: 'displayName',
    numeric: false,
    disablePadding: false,
    label: 'Display Name',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'userNameStatus',
    numeric: false,
    disablePadding: false,
    label: 'Username Status',
  },
  {
    id: 'accountStatus',
    numeric: false,
    disablePadding: false,
    label: 'Account Status',
  },
  {
    id: 'reportsReceived',
    numeric: true,
    disablePadding: false,
    label: 'Reports Received',
  },
  {
    id: 'reportsMade',
    numeric: true,
    disablePadding: false,
    label: 'Reports Made',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Username Created Date',
  },
]

interface TableProps {
  numSelected: number
  onRequestSort: (event: MouseEvent<unknown>, property: keyof User) => void
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

const TableHeader = (props: TableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const { countries, setCountries, statuses, setStatuses } = inputDataStore()
  const { filters, setFilters } = useFilterStore()

  const createSortHandler =
    (property: keyof User) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  const onType = (value: string, id: keyof UserFilters) => {
    if (value.length > 2) {
      setFilters({ ...filters, [id]: value })
    } else {
      if (id in filters) {
        delete filters[id]
        setFilters({ ...filters })
      }
    }
  }

  const renderFilter = (id: string) => {
    switch (id) {
      case 'userName':
      case 'displayName':
      case 'phoneNumber':
      case 'email':
        return (
          <TextField
            sx={{ minWidth: '180px' }}
            type='text'
            onChange={(e) => onType(e.target.value, id)}
          />
        )
      case 'id':
      case 'reportsReceived':
      case 'reportsMade':
        return (
          <TextField
            sx={{ minWidth: '100px' }}
            type='number'
            onChange={(e) => setFilters({ ...filters, [id]: e.target.value })}
          />
        )

      case 'userNameStatus':
      case 'accountStatus':
        return (
          <CustomSelect
            id={id}
            getOptions={getStatuses}
            options={statuses}
            setOptions={setStatuses}
          />
        )
      case 'role':
      case 'country':
        return (
          <CustomSelect
            id={id}
            getOptions={getCountries}
            options={countries}
            setOptions={setCountries}
          />
        )
      case 'createdAt':
        return <CustomDatePicker filters={filters} setFilters={setFilters} />

      default:
        break
    }
    return <p>id</p>
  }

  return (
    <TableHead>
      <TableRow sx={{ fontWeight: 400 }}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} width={300}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <p className='font-open-sans'>{headCell.label}</p>
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : undefined}
            </TableSortLabel>
            {renderFilter(headCell.id)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export const CustomTable = ({
  rows,
  selected,
  setSelected,
}: {
  rows: User[]
  selected: number[]
  setSelected: Function
}) => {
  const navigate = useNavigate()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof User>('userName')
  const [open, setOpen] = useState<boolean>(false)

  const { page, pageLimit, count, setPage, setPageLimit } = usePaginationStore()

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof User
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: readonly number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageLimit(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * pageLimit - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxWidth: 1535, minWidth: 750 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size='medium'
          >
            <TableHeader
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(
                  typeof row.id === 'string' ? parseInt(row.id) : row.id
                )
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      !open
                        ? handleClick(
                          event,
                          typeof row.id === 'string'
                            ? parseInt(row.id)
                            : row.id
                        )
                        : undefined
                    }
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer', fontSize: '16px', width: '100%' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <p className='font-open-sans text-charcoal-black w-[70px]'>
                        {row.id}
                      </p>
                    </TableCell>
                    <TableCell
                      align='left'
                      onClick={() => navigate(`user-info/${row.id}`)}
                    >
                      <p className='font-open-sans underline text-stormy-teal w-[200px]'>
                        {row.userName}
                      </p>
                    </TableCell>
                    <TableCell align='left'>
                      <p className='font-open-sans text-charcoal-black w-[200px]'>
                        {row.displayName}
                      </p>
                    </TableCell>
                    <TableCell align='left'>
                      <p className='font-open-sans text-charcoal-black w-[150px]'>
                        {row.phoneNumber}
                      </p>
                    </TableCell>
                    <TableCell align='left'>
                      <p className='font-open-sans text-charcoal-black w-[230px]'>
                        {row.email}
                      </p>
                    </TableCell>
                    <TableCell align='left'>
                      <StatusLabel label={row.userNameStatus} />
                    </TableCell>
                    <TableCell align='left'>
                      <StatusLabel label={row.accountStatus} />
                    </TableCell>
                    <TableCell align='left'>
                      <ReportReceivedLabel
                        userId={row.id}
                        label={row.reportsReceived}
                        navigate={navigate}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <ReportMadeLabel label={row.reportsMade} />
                    </TableCell>
                    <TableCell align='center'>
                      <p className='font-open-sans text-charcoal-black w-[150px]'>
                        {row.country.name}
                      </p>
                    </TableCell>
                    <TableCell align='center'>
                      <p className='font-open-sans text-charcoal-black w-[180px]'>
                        {format(row.createdAt, 'MM/dd/yyyy')}
                      </p>
                    </TableCell>
                  </TableRow>
                )
              })}
              {rows.length === 0 && (
                <TableRow
                  style={{
                    height: 106 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6}>
                    <p className='font-open-sans text-charcoal-black w-[190px]'>
                      No results found
                    </p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage='Items per page:'
          component='div'
          count={count}
          rowsPerPage={pageLimit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ReportsModal open={open} setOpen={setOpen} />
    </Box>
  )
}
