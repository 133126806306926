import { Button, IconButton } from '@mui/material'
import { ConfirmModal, CustomDrawer, CustomMenu } from '../components'
import { CustomTable } from '../components/CustomTable'
import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { getUsers, updateStatus } from '../services/users'
import { User } from '../constants/interfaces'
import useFilterStore from '../stores/filterStore'
import { usePaginationStore } from '../stores/pagination'

export const Dashboard = () => {
  const [selected, setSelected] = useState<number[]>([])
  const [status, setStatus] = useState<string>('')
  const [rows, setRows] = useState<User[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const { filters } = useFilterStore()
  const { page, setCount, pageLimit } = usePaginationStore()

  useEffect(() => {
    const apiCall = async () => {
      try {
        const response = await getUsers(filters, page, pageLimit)
        if (response.statusCode === 200) {
          setCount((response as any).meta?.pagination?.count)
          setRows(response.data.users)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const timeoutId = setTimeout(() => {
      apiCall()
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [filters, page, pageLimit, setCount])

  const onStatusChange = async (status: string) => {
    setStatus(status)
    setOpen(true)
  }

  const onConfirm = async () => {
    try {
      const response = await updateStatus({ ids: selected, status })
      if (response.statusCode === 200) {
        const users = await getUsers(filters, page, pageLimit)
        if (users.statusCode === 200) {
          setCount((response as any).meta?.pagination?.count)
          setRows(users.data.users)
        }
      }
    } catch (error) {
      alert(error)
    }
    setOpen(false)
  }

  return (
    <div className='flex w-full h-[100vh] bg-[#f5f7f6]'>
      <div className='flex w-[320px] fixed'>
        <CustomDrawer />
      </div>
      <div className='flex flex-col w-full pl-[355px] pt-[30px] pr-4'>
        <div>
          <p className='text-[24px] font-weigth-normal text-[#12314D] leading-10'>
            User Management
          </p>
        </div>

        <div className='flex w-full justify-between mb-2'>
          <p className='font-open-sans text-[18px] font-weigth-semibold text-[#171a1c] leading-10'>
            User Accounts
          </p>
          <Button variant='contained'>Add User</Button>
        </div>

        <div
          className={`flex h-[50px] w-full justify-between items-center mb-2 ${selected.length > 0 ? 'bg-stormy-teal' : 'bg-cloud-white'
            } rounded-md p-2`}
        >
          {selected.length > 0 ? (
            <div className='flex items-center'>
              <IconButton onClick={() => setSelected([])}>
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
              <p className='font-open-sans text-base font-semibold text-white'>
                {selected.length} Selected
              </p>
            </div>
          ) : (
            <p className='font-open-sans text-base font-semibold text-gray-600'>
              None Selected
            </p>
          )}
          <div className='flex flex-row space-x-2'>
            <div
              className={`border-solid border-2 border-${selected.length > 0 ? 'white' : '#ecefee'
                } rounded-[10px]`}
            >
              <Button
                variant='contained'
                sx={{ height: '100%' }}
                disabled={selected.length === 0}
              >
                Reset Password
              </Button>
            </div>
            <CustomMenu
              title='Change Account Status'
              selected={selected.length > 0}
              statusType='account'
              onSelect={onStatusChange}
            />
          </div>
        </div>
        <CustomTable
          rows={rows}
          selected={selected}
          setSelected={setSelected}
        />
        <ConfirmModal
          open={open}
          handleClose={() => setOpen(false)}
          text={`Are you sure you want to change the status to ${status} for the selected ID/IDs?`}
          onConfirm={onConfirm}
        />
      </div>
    </div>
  )
}
