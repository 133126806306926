import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Avatar from '../assets/avatar.svg'
import Group from '../assets/group.svg'
import { useNavigate } from 'react-router-dom'

export const UserDrawer = ({
  section,
  setSection,
}: {
  section: string
  setSection: Function
}) => {
  const navigate = useNavigate()
  const drawerWidth = 320
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#f5f7f6',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <div className='flex items-center p-4'>
        <div className='mx-2 cursor-pointer' onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ color: '#45ae9f' }} />
        </div>
        <h1 className='text-[26px] font-weigth-normal text-deep-blue leading-10 tracking-normal'>
          User Management
        </h1>
      </div>
      <div className='ml-6 my-6 flex'>
        <div className='flex w-[60px] h-[60px] aspect-square'>
          <img
            src={Avatar}
            alt='avatar'
            className='h-auto max-w-full rounded-full shadow-lg object-cover'
          />
        </div>
        <div className='flex flex-col ml-4 mt-1'>
          <p className='text-[20px] font-valera-round font-weigth-normal text-deep-blue tracking-normal'>
            Oki
          </p>
          <p className='text-[14px] font-weigth-normal text-mint-turquoise'>
            @okispeaks
          </p>
          <div className='w-[58px] mt-3 p-2 flex justify-center bg-cloud-white rounded-lg'>
            <p className='text-[12px] font-semibold font-weigth-normal leading-5 text-stormy-teal'>
              User
            </p>
          </div>
        </div>
      </div>

      <p className='ml-6 font-open-sans text-[14px] leading-6'>ANALYTICS</p>
      <List sx={{ backgroundColor: '#f5f7f6', marginLeft: '25px' }}>
        <ListItem key={'Consumption'} disablePadding>
          <ListItemButton onClick={() => setSection('Consumption')}>
            <ListItemIcon>
              <img src={Group} alt='group' />
            </ListItemIcon>
            <ListItemText primary={'Consumption'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'Creation'} disablePadding>
          <ListItemButton onClick={() => setSection('Creation')}>
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary={'Creation'} />
          </ListItemButton>
        </ListItem>
      </List>
      <div>
        <p className='ml-6 font-open-sans text-[14px] leading-6'>ACTIVITY</p>
        <List sx={{ backgroundColor: '#f5f7f6', marginLeft: '25px' }}>
          <ListItem key={'History'} disablePadding>
            <ListItemButton onClick={() => setSection('History')}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={'History'} />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}
