// ----------------------------------------------------------------------

export default function Checkbox () {
  return {
    MuiCheckbox: {
      defaultProps: {
        // icon: <CheckboxIcon />,
        // checkedIcon: <CheckboxCheckedIcon />,
        // indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          paddingTop: 1,
          paddingBottom: 1,
          '&:hover': {
            backgroundColor: '#f5f7f6'
          }
        }
      },
    },
  }
}
