import { createStore } from 'zustand/vanilla'
import { devtools, persist } from 'zustand/middleware'
import { AuthState, userState } from '../constants/interfaces'

const initAuthState: AuthState = {
  user: null,
  accessToken: null,
  recoveryToken: null,
  refreshToken: null,
  setUser: Function,
  setAccessToken: Function,
  setRecoveryToken: Function,
  setRefreshToken: Function,
  setLogout: Function,
}

export const authStore = createStore<AuthState>()(
  devtools(
    persist(
      (set) => ({
        ...initAuthState,
        setUser: (u: userState) => set(() => ({ user: u })),
        setProfilePicture: (url: string) =>
          set((state: AuthState) => ({
            user: { ...state.user!, profilePictureUrl: url },
          })),
        setAccessToken: (accessToken: string) =>
          set(() => ({ accessToken: accessToken })),
        setRecoveryToken: (recoveryToken: string) =>
          set(() => ({ recoveryToken: recoveryToken })),
          setRefreshToken: (refreshToken: string) =>
          set(() => ({ refreshToken: refreshToken })), 
        setLogout: () =>
          set(() => ({
            user: null,
            accessToken: null,
            userName: null,
          })),
      }),
      { name: 'AuthStoreVanilla' }
    )
  )
)
