import { Button, Checkbox, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { changePassword } from '../../services/auth'

export const ChangePasswordForm = () => {
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true)
  const [currentIsHidden, setCurrentIsHidden] = useState<boolean>(true)
  const [confirmIsHidden, setConfirmIsHidden] = useState<boolean>(true)

  const navigate = useNavigate()
  const [error, setError] = useState('')

  const passRegexForChars = /^[A-Za-z\d@$!%*?&Ññ]{8,20}$/
  const passRegexForUpperCase = /.*[A-Z].*/
  const passRegexForLowerCase = /.*[a-z].*/
  const passRegexForNumber = /.*\d.*/

  const validationSchema = Yup.object({
    current: Yup.string()
      .min(8, 'Password too short')
      .max(16, 'Password too long')
      .matches(
        passRegexForChars,
        'Your password must have between 8 and 20 characters',
      )
      .matches(
        passRegexForUpperCase,
        'Your password must have at least 1 uppercase character',
      )
      .matches(
        passRegexForLowerCase,
        'Your password must have at least 1 lowercase character',
      )
      .matches(passRegexForNumber, 'Your password must have at least 1 number')
      .required('Password must be written down'),
    password: Yup.string()
      .min(8, 'Password too short')
      .max(16, 'Password too long')
      .matches(
        passRegexForChars,
        'Your password must have between 8 and 20 characters',
      )
      .matches(
        passRegexForUpperCase,
        'Your password must have at least 1 uppercase character',
      )
      .matches(
        passRegexForLowerCase,
        'Your password must have at least 1 lowercase character',
      )
      .matches(passRegexForNumber, 'Your password must have at least 1 number')
      .required('Password must be written down'),
    confirm: Yup.string()
      .oneOf([Yup.ref('password')], 'Your passwords do not match')
      .required('Password must be written down'),
  })

  const initialValues = {
    current: '',
    password: '',
    confirm: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        const { password, current } = values

        const response = await changePassword(current, password)

        if (response.statusCode === 200) {
          navigate('/')
        }

      } catch (error) {
        setError('Incorrect user identification or password. Please try again.')
      }
    },
  })

  useEffect(() => {
    setError('')
  }, [values])

  return (
    <form
      className='flex flex-col w-full gap-6 2xl:px-36 md:px-8'
      onSubmit={handleSubmit}
    >
      <div className='flex w-full'>
        <Typography
          fontFamily={'Varela Round, sans-serif'}
          fontSize={'34px'}
          letterSpacing={'-0.51px'}
          color={'#000'}
        >
          Change password
        </Typography>
      </div>
      {error !== '' && (
        <div className='flex w-[full] justify-center bg-[#d7263d] rounded-md py-3 px-2'>
          <Typography
            sx={{
              width: '432px',
              height: '22px',
              fontFamily: 'OpenSans',
              fontSize: '16px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: '#fff',
            }}
          >
            {error}
          </Typography>
        </div>
      )}
      <div className='flex flex-col w-full justify-center gap-2'>
        <TextField
          id='current'
          fullWidth
          label='Current Password'
          type={currentIsHidden ? 'password' : 'text'}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.current}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.current && touched.current) as boolean}
          helperText={
            !!errors.current &&
            touched.current !== undefined &&
            (errors.current as string)
          }
        />
        <div className='flex flex-row w-full justify-between'>
          <Checkbox
            checked={!currentIsHidden}
            color='secondary'
            onChange={(e) => {
              setCurrentIsHidden((prev) => !prev)
            }}
          />
          <div className='w-full truncate'>
            <Typography fontWeight='400' color='#00486D'>
              Show password
            </Typography>
          </div>
        </div>
      </div>

      <div className='flex flex-col w-full justify-center gap-2'>
        <TextField
          id='password'
          fullWidth
          label='New Password'
          type={passwordIsHidden ? 'password' : 'text'}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.password && touched.password) as boolean}
          helperText={
            !!errors.password &&
            touched.password !== undefined &&
            (errors.password as string)
          }
        />
        <div className='flex flex-row w-full justify-between'>
          <Checkbox
            checked={!passwordIsHidden}
            color='secondary'
            onChange={(e) => {
              setPasswordIsHidden((prev) => !prev)
            }}
          />
          <div className='w-full truncate'>
            <Typography fontWeight='400' color='#00486D'>
              Show password
            </Typography>
          </div>
        </div>
      </div>

      <div className='flex flex-col w-full justify-center gap-2'>
        <TextField
          id='confirm'
          fullWidth
          label='Confirm Password'
          type={confirmIsHidden ? 'password' : 'text'}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.confirm}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.confirm && touched.confirm) as boolean}
          helperText={
            !!errors.confirm &&
            touched.confirm !== undefined &&
            (errors.confirm as string)
          }
        />
        <div className='flex flex-row w-full justify-between'>
          <Checkbox
            checked={!confirmIsHidden}
            color='secondary'
            onChange={(e) => {
              setConfirmIsHidden((prev) => !prev)
            }}
          />
          <div className='w-full truncate'>
            <Typography fontWeight='400' color='#00486D'>
              Show password
            </Typography>
          </div>
        </div>
      </div>
      <div className='flex w-full justify-center '>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!isValid}
          fullWidth
        >
          Submit
        </Button>
      </div>
    </form>
  )
}
