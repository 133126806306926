import axios, { AxiosResponse } from 'axios'
import { GenericResponse } from '../constants/interfaces'
import { http } from '../utils/http'

interface LoginData {
  accessToken: string
  refreshToken: string
  email: string
  userType: string
  userName: string
}

interface LoginResponse {
  data: LoginData
  message: string
  statusCode: Number
}

interface ValidateCodeResponse extends GenericResponse {
  data: {
    recoveryToken: string
  }
}
const apiUrl = process.env.REACT_APP_API_URL

export const login = async (
  email: string,
  password: string,
): Promise<LoginData> => {
  try {
    const response: AxiosResponse<LoginResponse> = await axios.post(
      `${apiUrl}/api/auth/login`,
      {
        email,
        password,
      },
    )

    return response.data.data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const iniciatePasswordRecovery = async (
  email: string,
): Promise<AxiosResponse<GenericResponse>> => {
  try {
    const response: AxiosResponse<GenericResponse> = await axios.patch(
      `${apiUrl}/api/auth/password-recovery/initiate`,
      {
        email,
      },
    )

    return response
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const validateCode = async (
  email: string,
  recoveryCode: string,
): Promise<ValidateCodeResponse> => {
  try {
    const response: AxiosResponse<ValidateCodeResponse> = await axios.patch(
      `${apiUrl}/api/auth/password-recovery/validate`,
      {
        email,
        recoveryCode,
      },
    )

    return response.data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const completePasswordRecovery = async (
  password: string,
  recoveryToken: string,
): Promise<GenericResponse> => {
  try {
    const response: AxiosResponse<GenericResponse> = await axios.patch(
      `${apiUrl}/api/auth/password-recovery/complete`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${recoveryToken}`,
        },
      },
    )

    return response.data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<GenericResponse> => {
  try {
    const response: AxiosResponse<GenericResponse> = await http.patch(
      `${apiUrl}/api/auth/password-change`,
      {
        oldPassword,
        newPassword,
      },
    )

    return response.data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const logout = async (): Promise<any> => {
  try {
    const response = await http.post(`${apiUrl}/api/auth/logout`, {})
    return response.data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}
