import { UserDrawer } from '../../components'
import { useParams } from 'react-router-dom'
import { Consumption } from './Consumption'
import { Creation } from './Creation'
import { History } from './History'
import { useState } from 'react'

export const UserInfo = () => {
  const { id } = useParams()
  const [section, setSection] = useState('Consumption')

  return (
    <div className='flex w-full h-full min-h-screen bg-background'>
      <UserDrawer section={section} setSection={setSection}/>
      {section === 'Consumption' && <Consumption userId={id} />}
      {section === 'Creation' && <Creation userId={id} />}
      {section === 'History' && <History userId={id} />}
    </div>
  )
}
