import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface UserFilters {
  id?: number
  userName?: string
  displayName?: string
  phoneNumber?: string
  email?: string
  userNameStatus?: string
  accountStatus?: string
  role?: string
  reportsReceived?: number
  reportsMade?: number
  country?: number
  createdAt?: Date
  limit?: number
  offset?: number
}

export interface FilterState {
  filters: UserFilters
  setFilters: Function
}

const useFilterStore = create<FilterState>()(
  devtools((set) => ({
    filters: {},
    setFilters: (data: UserFilters) => {
      set(() => ({ filters: data }))
    },
  }))
)

export default useFilterStore
