import { Button, Checkbox, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { login } from '../../services/auth'
import { useStore } from 'zustand'
import { authStore } from '../../stores/auth'
import { useNavigate } from 'react-router-dom'

export const LoginForm = () => {
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true)
  const { setUser, setAccessToken, setRefreshToken } = useStore(authStore)
  const navigate = useNavigate()
  const [error, setError] = useState('')

  const passRegexForChars = /^[A-Za-z\d@$!%*?&Ññ]{8,20}$/
  const passRegexForUpperCase = /.*[A-Z].*/
  const passRegexForLowerCase = /.*[a-z].*/
  const passRegexForNumber = /.*\d.*/

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, 'Invalid email')
      .required('Email must be written down'),
    password: Yup.string()
      .min(8, 'Password too short')
      .max(16, 'Password too long')
      .matches(
        passRegexForChars,
        'Your password must have between 8 and 20 characters',
      )
      .matches(
        passRegexForUpperCase,
        'Your password must have at least 1 uppercase character',
      )
      .matches(
        passRegexForLowerCase,
        'Your password must have at least 1 lowercase character',
      )
      .matches(passRegexForNumber, 'Your password must have at least 1 number')
      .required('Password must be written down'),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        const { email, password } = values
        const response = await login(email, password)
        if (response) {
          setAccessToken(response.accessToken)
          setRefreshToken(response.refreshToken)
          setUser({
            userName: response.userName,
            email: response.email,
            userType: response.userType,
          })
        }

        navigate('/dashboard')
      } catch (error) {
        setError('Incorrect user identification or password. Please try again.')
      }
    },
  })

  useEffect(() => {
    setError('')
  }, [values])

  return (
    <form
      className='flex flex-col w-full gap-6 2xl:px-36 md:px-8'
      onSubmit={handleSubmit}
    >
      <div className='flex w-full'>
        <Typography
          fontFamily={'Varela Round, sans-serif'}
          fontSize={'34px'}
          letterSpacing={'-0.51px'}
          color={'#000'}
        >
          Sign in to Qclap
        </Typography>
      </div>
      {error !== '' && (
        <div className='flex w-[full] justify-center bg-[#d7263d] rounded-md py-3 px-2'>
          <Typography
            sx={{
              width: '432px',
              height: '22px',
              fontFamily: 'OpenSans',
              fontSize: '16px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: '#fff',
            }}
          >
            {error}
          </Typography>
        </div>
      )}
      <div className='flex w-full justify-center'>
        <TextField
          id='email'
          fullWidth
          label='Email'
          type='text'
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.email && touched.email) as boolean}
          helperText={
            !!errors.email &&
            touched.email !== undefined &&
            (errors.email as string)
          }
        />
      </div>
      <div className='flex flex-col w-full justify-center gap-4'>
        <TextField
          id='password'
          fullWidth
          label='Password'
          type={passwordIsHidden ? 'password' : 'text'}
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.password && touched.password) as boolean}
          helperText={
            !!errors.password &&
            touched.password !== undefined &&
            (errors.password as string)
          }
        />
        <div className='flex flex-row w-full justify-between'>
          <Checkbox
            checked={!passwordIsHidden}
            color='secondary'
            onChange={(e) => {
              setPasswordIsHidden((prev) => !prev)
            }}
          />
          <div className='w-full truncate'>
            <Typography fontWeight='400' color='#00486D'>
              Show password
            </Typography>
          </div>
        </div>
      </div>
      <div className='flex w-full justify-center '>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!isValid}
          fullWidth
        >
          Sign in
        </Button>
      </div>
      <div className='flex w-full justify-center gap-3'>
        <Typography
          fontFamily={'Open Sans, sans-serif'}
          fontSize={'16px'}
          color={'#45ae9f'}
          className='cursor-pointer'
          onClick={() => navigate('/forgot-password')}
        >
          Forgot your password?
        </Typography>
      </div>
    </form>
  )
}
