export const capitalizeFirstLetter = (inputString: string): string => {
  if (!inputString) {
    return inputString
  } else {
    const firstLetter = inputString.charAt(0).toUpperCase()
    const restOfString = inputString.slice(1).toLowerCase()
    return firstLetter + restOfString
  }
}

export const getCountForPagination = (count: number, pageLimit: number) => {
  return Math.ceil(count / pageLimit)
}
