import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Option, Status } from '../constants/types'
import useFilterStore, { UserFilters } from '../stores/filterStore'
import { ClearIcon } from '@mui/x-date-pickers'
import { IconButton } from '@mui/material'
import { capitalizeFirstLetter } from '../utils/helpers'

export const CustomSelect = ({
  id,
  getOptions,
  options,
  setOptions,
}: {
  id: string
  getOptions: Function
  options: any[]
  setOptions: Function
}) => {
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { filters, setFilters } = useFilterStore()

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
    if (id === 'country') {
      setFilters({ ...filters, [id]: event.target.value })
    } else {
      const value = capitalizeFirstLetter(event.target.value)
      setFilters({ ...filters, [id]: value })
    }
  }

  const populateOptions = async () => {
    if (options.length === 0) {
      setIsLoading(true)
      const response = await getOptions()
      setOptions(response.data)
      setIsLoading(false)
    }
  }

  const handleClear = () => {
    setValue('')
    delete filters[id as keyof UserFilters]
    setFilters({ ...filters })
  }

  const renderOptions = () => {
    if (id === 'country') {
      return options.map((option: Option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))
    } else {
      return options.map((option: Status) => (
        <MenuItem key={option.code} value={option.code}>
          {option.description}
        </MenuItem>
      ))
    }
  }

  return (
    <FormControl sx={{ heigth: '40px', minWidth: '150px' }} size='small'>
      <Select
        id={id}
        value={value}
        onChange={handleChange}
        onOpen={populateOptions}
        endAdornment={
          <IconButton
            sx={{ visibility: value !== '' ? 'visible' : 'hidden' }}
            onClick={handleClear}
          >
            <ClearIcon />
          </IconButton>
        }
      >
        {isLoading ? <p className='p-2'>Loading...</p> : renderOptions()}
      </Select>
    </FormControl>
  )
}
