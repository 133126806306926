import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enUS } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { UserFilters } from '../stores/filterStore'

export const CustomDatePicker = ({
  filters,
  setFilters,
}: {
  filters: UserFilters
  setFilters: Function
}) => {
  return (
    <LocalizationProvider adapterLocale={enUS} dateAdapter={AdapterDateFns}>
      <DatePicker
        onChange={(value) => {
          setFilters({ ...filters, createdAt: value })
        }}
        slotProps={{
          field: { clearable: true, onClear: () => {
            delete filters.createdAt
            setFilters(filters)
          } },
        }}
        sx={{ minWidth: '190px'}}
      />
    </LocalizationProvider>
  )
}
