import { Theme } from '@mui/material/styles'
import { ButtonProps } from '@mui/material'

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

export default function Button (theme: Theme) {

  const rootStyle = (ownerState: ButtonProps) => {
    const inheritColor = ownerState.color === 'inherit'

    const containedVariant = ownerState.variant === 'contained'

    const outlinedVariant = ownerState.variant === 'outlined'

    const smallSize = ownerState.size === 'small'

    const largeSize = ownerState.size === 'large'

    const defaultStyle = {
      fontSize: '14px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
      letterSpacing: 'normal',
      textAlign: 'center',
      textTransform: 'none',
      padding: '6px 20px',
      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.common.white,

          '&:hover': {
            color: theme.palette.secondary.light
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          // borderColor: alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            borderColor: theme.palette.text.primary,
            backgroundColor: theme.palette.action.hover
          }
        }),
      })
    }

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          backgroundColor: `${theme.palette[color].main}`,
          '&:hover': {
            background: `${theme.palette[color].light}`,
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette[color].main,
          borderWidth: 3,
          borderColor: theme.palette[color].main,
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            borderWidth: 3,
            borderColor: theme.palette[color].light,
          }
        }),
      })
    }))

    const disabledState = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        '&.Mui-disabled': {
          // background: '#D3D3D3',
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          opacity: 0.4,
          // OUTLINED
          ...(outlinedVariant && {
            color: theme.palette.primary.main,
            background: theme.palette.common.white,
            borderWidth: 3,
            borderColor: theme.palette.primary.main,
          }),
        }
      })
    }))


    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 13,
      }),
      ...(largeSize && {
        height: 48,
        fontSize: 15,
      })
    }

    return [...colorStyle, defaultStyle, disabledState, size]
  }

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => rootStyle(ownerState)
      }
    },
    PrivatePickersYear: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[700],
          '& :hover': {
            borderRadius: '10px',
          },
          '& .Mui-selected': {
            borderRadius: '10px',
            backgroundColor: `${theme.palette.secondary.main} !important`,
          },
          '& .Mui-disabled': {
            color: theme.palette.grey[100],
            fontWeight: 'lighter'
          },
        }
      }
    }
  }
}
