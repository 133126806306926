import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { UserData } from '../constants/interfaces'
import Avatar from '../assets/avatar.svg'
import { format } from 'date-fns'

type ReportedPost = {
  user: UserData
  createdAt: Date
  repeated: number
  description: string
}

export const ReportedPost = ({ reportData }: { reportData?: ReportedPost }) => {
  let description =
    '3 lines ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Sum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. 3 lines ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Sum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
  const scrollUp = () => {
    const scrollableDiv = document.getElementById('scroll-text')
    scrollableDiv!.scrollTop += 30
  }

  const scrollDown = () => {
    const scrollableDiv = document.getElementById('scroll-text')
    scrollableDiv!.scrollTop -= 30
  }

  return (
    <div className='flex flex-row w-full h-[160px]'>
      <div className='w-[600px] bg-white rounded-lg shadow-lg relative'>
        <div className='flex flex-row space-x-3 p-3'>
          <div className='flex w-[40px] h-[40px] aspect-square'>
            <img
              src={Avatar}
              alt='avatar'
              className='h-auto max-w-full rounded-full shadow-lg object-cover border-solid border-2 border-mint-turquoise'
            />
          </div>
          <div className='flex flex-col'>
            <p className='text-[14px] font-valera-round text-charcoal-black font-stretch-normal font-bold leading-1.43 tracking-normal'>
              Pratima Mukhopadhyay
            </p>
            <p className='text-[12px] font-open-sans text-pewter-gray font-light tracking-normal '>
              Baltimore
            </p>
          </div>
          <p className=' text-pewter-gray font-open-sans'>
            @patr1ma • {format(new Date(), 'MM/dd/yy, HH:mm:ss')}
          </p>
        </div>
        <div className='relative'>
          <div
            id='scroll-text'
            className='w-[539px] h-[65px] overflow-hidden px-3'
          >
            <p>{description}</p>
          </div>
          <div className='w-full h-[16px] absolute bg-gradient-to-b from-transparent to-white bottom-0'></div>
        </div>
        <div className='flex flex-col items-end absolute bottom-2 right-3 text-pewter-gray'>
          <KeyboardArrowUpIcon fontSize='small' onClick={() => scrollDown()} />
          <KeyboardArrowDownIcon fontSize='small' onClick={() => scrollUp()} />
        </div>
        <div
          style={{
            clipPath: ' polygon(18% 0, 100% 0%, 100% 100%, 18% 100%, 0% 50%)',
          }}
          className='flex w-[80px] h-[40px] bg-raspberry-red absolute rounded-r-lg top-2 -right-1 text-white items-center space-x-2 pl-5 font-[14px] font-open-sans'
        >
          <ErrorOutlineIcon /> <p>8</p>
        </div>
      </div>
      <div className='flex flex-col space-y-3 w-[160px] ml-6'>
        <Button variant='outlined'>Delete Post</Button>
        <Button variant='outlined'>Clear Report</Button>
      </div>
    </div>
  )
}

export const ReportsModal = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (v: boolean) => void
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='dialog-title'
      open={open}
      fullWidth={true}
      maxWidth='md'
      PaperProps={{
        style: {
          width: 840,
          height: 600,
        },
      }}
      sx={{
        'MuiDialogContent-root': {
          padding: 0,
        },
      }}
    >
      <div className='flex flex-row justify-between items-center'>
        <DialogTitle sx={{ m: 0, p: 2 }} id='dialog-title'>
          <p className='font-valera-round text-[20px] font-weigth-normal'>
            User Reports
          </p>
        </DialogTitle>
        <div className='mx-2 space-x-2'>
          <Button color='primary' variant='outlined'>
            Clear All Reports
          </Button>
          <Button color='error' variant='outlined'>
            Suspend Account
          </Button>
        </div>
      </div>
      <DialogContent
        dividers
        sx={{ backgroundColor: '#ecefee' }}
        className='space-y-3'
      >
        <ReportedPost />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
