import { AxiosResponse } from 'axios'
import { http } from '../utils/http'
import {
  GenericResponse,
  OptionsResponse,
  StatusesResponse,
  User,
} from '../constants/interfaces'
import { UserFilters } from '../stores/filterStore'
import { format } from 'date-fns'

interface GetUsersResponse extends GenericResponse {
  data: { users: User[] }
}

const apiUrl = process.env.REACT_APP_API_URL

const buildQueryString = (filters: UserFilters): string => {
  const queryStringArray: string[] = []

  for (const prop in filters) {
    if (
      filters.hasOwnProperty(prop) &&
      filters[prop as keyof UserFilters] !== undefined
    ) {
      let value = filters[prop as keyof UserFilters]

      // Format Date object to string
      if (value instanceof Date) {
        value = format(value, 'yyyy-MM-dd')
      }

      if (value)
        queryStringArray.push(
          `${encodeURIComponent(prop)}=${encodeURIComponent(value)}`,
        )
    }
  }

  if (queryStringArray.length > 0) {
    return '?' + queryStringArray.join('&')
  } else {
    return ''
  }
}

export const getUsers = async (
  filters: UserFilters,
  page: number,
  pageLimit: number
): Promise<GetUsersResponse> => {
  try {
    const query = buildQueryString({ ...filters, limit: pageLimit, offset: page * pageLimit })
    const { data }: AxiosResponse<any> = await http.get(
      `${apiUrl}/api/users${query}`,
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const getCountries = async (): Promise<OptionsResponse> => {
  try {
    const { data }: AxiosResponse<any> = await http.get(
      `${apiUrl}/api/countries`,
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const getStatuses = async (): Promise<StatusesResponse> => {
  try {
    const { data }: AxiosResponse<any> = await http.get(
      `${apiUrl}/api/users/statuses`,
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const updateStatus = async ({
  ids,
  status,
}: {
  ids: number[]
  status: string
}): Promise<GenericResponse> => {
  try {
    const { data }: AxiosResponse<any> = await http.patch(
      `${apiUrl}/api/users/statuses`,
      { ids, status },
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}

export const getReportedPosts = async (
  userId: number,
  page: number,
  pageLimit: number,
): Promise<any> => {
  try {
    const { data }: AxiosResponse<any> = await http.get(
      `${apiUrl}/api/users/${userId}/reported-posts?offset=${(page - 1) * pageLimit}&limit=${pageLimit}`,
    )

    return data
  } catch (error) {
    throw new Error(
      (error as any).response?.data?.message || 'Something went wrong',
    )
  }
}
