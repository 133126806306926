import { Button, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useLocation, useNavigate } from 'react-router-dom'
import { validateCode } from '../../services/auth'
import { useStore } from 'zustand'
import { authStore } from '../../stores/auth'

export const ValidateCodeForm = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const { setRecoveryToken } = useStore(authStore)
  const location = useLocation()
  const email = location.state.email

  const validationSchema = Yup.object({
    recoveryCode: Yup.string().required('Recovery code must be written down'),
  })

  const initialValues = {
    recoveryCode: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        const { recoveryCode } = values
        const response = await validateCode(email, recoveryCode)

        if (response.statusCode === 200) {
          setRecoveryToken(response.data.recoveryToken)
          navigate('/new-password')
        }

      } catch (error) {
        setError('Invalid code, Please try again.')
      }
    },
  })

  useEffect(() => {
    setError('')
  }, [values])

  return (
    <form
      className='flex flex-col w-full gap-6 2xl:px-36 md:px-8'
      onSubmit={handleSubmit}
    >
      <div className='flex w-full'>
        <Typography
          fontFamily={'Varela Round, sans-serif'}
          fontSize={'34px'}
          letterSpacing={'-0.51px'}
          color={'#000'}
        >
          Validate Code
        </Typography>
      </div>
      {error !== '' && (
        <div className='flex w-[full] justify-center bg-[#d7263d] rounded-md py-3 px-2'>
          <Typography
            sx={{
              width: '432px',
              height: '22px',
              fontFamily: 'OpenSans',
              fontSize: '16px',
              fontWeight: 'normal',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: '#fff',
            }}
          >
            {error}
          </Typography>
        </div>
      )}
      <div className='flex w-full justify-center'>
        <TextField
          id='recoveryCode'
          fullWidth
          label='Recovery Code'
          type='text'
          variant='filled'
          InputProps={{ disableUnderline: true }}
          value={values.recoveryCode}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(!!errors.recoveryCode && touched.recoveryCode) as boolean}
          helperText={
            !!errors.recoveryCode &&
            touched.recoveryCode !== undefined &&
            (errors.recoveryCode as string)
          }
        />
      </div>

      <div className='flex w-full justify-center '>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!isValid}
          fullWidth
        >
          Send code
        </Button>
      </div>
      <div className='flex w-full justify-center gap-3'>
        <Typography
          fontFamily={'Open Sans, sans-serif'}
          fontSize={'16px'}
          color={'#45ae9f'}
          className='cursor-pointer'
          onClick={() => navigate('/')}
        >
          Return to login
        </Typography>
      </div>
    </form>
  )
}
