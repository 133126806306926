import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Menu (theme: Theme) {
  return {
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginTop: 5,
          padding: 0,
          paddingLeft: 6,
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.secondary.light} !important`
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: '0px !important',
          backgroundColor: theme.palette.common.white,
          border: 'transparent 1px black',
        }
      }
    }
  }
}
