import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Country, Status } from '../constants/types'

interface InputDataState {
  countries: Country[]
  statuses: Status[]

  setCountries: Function
  setStatuses: Function
}

const inputDataStore = create<InputDataState>()(
  devtools((set) => ({
    countries: [],
    setCountries: (data: Country[]) => set(() => ({ countries: data })),
    statuses: [],
    setStatuses: (data: Status[]) => set(() => ({ statuses: data })),
  }))
)

export default inputDataStore
