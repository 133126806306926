import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export const ConfirmModal = ({
  open,
  text,
  onConfirm,
  handleClose
}: {
  open: boolean
  text: string,
  onConfirm: () => void,
  handleClose: () => void
}) => {


  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='dialog-title'
      open={open}
      fullWidth={true}
      maxWidth='md'
      PaperProps={{
        style: {
          width: 400,
          height: 200,
        },
      }}
      sx={{
        'MuiDialogContent-root': {
          padding: 0,
        },
      }}
    >
      <div className='flex flex-row justify-between items-center'>
        <DialogTitle sx={{ m: 0, p: 2 }} id='dialog-title'>
          <p className='font-valera-round text-[20px] font-weigth-normal'>
            Confirm
          </p>
        </DialogTitle>
      </div>
      <DialogContent
        dividers
        sx={{ backgroundColor: '#ecefee' }}
        className='space-y-3'
      >
        <p>{text}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onConfirm()}>
          Confirm
        </Button>
        <Button color='secondary' autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
