import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { useStore } from 'zustand'
import { authStore } from '../stores/auth'
import QClapIcon from '../assets/icon-clap-active.png'
import PersonIcon from '@mui/icons-material/Person'
import IconClap from '../assets/icon-clap-inactive.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

export const CustomDrawer = () => {
  const { user } = useStore(authStore)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const drawerWidth = 320

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#f5f7f6',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <div className='flex items-center p-4 cursor-pointer'>
        <img
          src={QClapIcon}
          alt='qclap-icon'
          className='h-[50px] w-[50px] mr-2'
        />
        <h1 className='text-[26px] font-weigth-normal text-[#12314D] leading-10 tracking-normal'>
          QClap Admin
        </h1>
      </div>
      <Divider />
      <List sx={{ backgroundColor: '#f5f7f6', marginLeft: '25px' }}>
        <ListItem key={'User Management'} disablePadding>
          <ListItemButton onClick={() => navigate('/dashboard')}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={'User Management'} />
          </ListItemButton>
        </ListItem>
      </List>
      <div className='flex flex-row space-x-3  absolute bottom-0 p-6'>
        <div className='flex w-[40px] h-[40px] aspect-square'>
          <img
            src={IconClap}
            alt='IconClap'
            className='h-auto max-w-full rounded-full shadow-lg object-cover'
          />
        </div>
        <div className='flex flex-col'>
          <p className='text-[14px] font-valera-round text-charcoal-black font-stretch-normal font-bold leading-1.43 tracking-normal'>
            Admin
          </p>
          <p className='text-[12px] font-open-sans text-pewter-gray font-light tracking-normal '>
            {user?.email}
          </p>
        </div>
        <div>
          <IconButton onClick={handleClick}>
            <KeyboardArrowDownIcon />
          </IconButton>

          <Menu
            id='basic-menu'
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ width: 180, maxWidth: '100%' }}
          >
            <div className='w-[150px]'>
              <MenuItem
                onClick={() => {
                  handleClose()
                  navigate('/change-password', { replace: true })
                }}
              >
                Change Password
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose()
                  navigate('/logout', { replace: true })
                }}
              >
                Logout
              </MenuItem>
            </div>
          </Menu>
        </div>
      </div>
    </Drawer>
  )
}
