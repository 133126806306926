// ----------------------------------------------------------------------

export default function Accordion () {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'white',
          '&::before': {
            backgroundColor: 'white'
          },
          '& .Mui-expanded': {
            minHeight: '0 !important',
          }
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
          '& .Mui-expanded': {
            margin: '0 !important',
          }
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 8px'
        }
      }
    }
  }
}
