// ----------------------------------------------------------------------

export function remToPx (value: string) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem (value: number) {
  return `${value / 16}rem`
}

export function responsiveFontSizes ({ xs, sm, md, lg }: { xs: number, sm: number, md: number, lg: number }) {
  return {
    '@media (max-width:600px)': {
      fontSize: pxToRem(xs)
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  }
}

// ----------------------------------------------------------------------

const PRIMARY_FONT = 'Open Sans, sans-serif'
const SECONDARY_FONT = 'Varela Round, sans-serif'


// NEW VARIANT
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    nav: true
    username: true
    dialog: true
  }
}

const typography = {
  fontFamily: PRIMARY_FONT,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: SECONDARY_FONT,
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: '-0.7px',
  },
  h2: {
    fontFamily: PRIMARY_FONT,
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.22,
    letterSpacing: '-0.52px'
  },
  h3: {
    fontWeight: 600,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ xs: 22, sm: 30, md: 36, lg: 36 })
  },
  h4: {
    fontWeight: 700,
    fontSize: pxToRem(26),
    ...responsiveFontSizes({ xs: 16, sm: 20, md: 22, lg: 22 })
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs: 14, sm: 16, md: 18, lg: 18 })
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 18, lg: 18 })
  },
  subtitle1: {
    fontFamily: SECONDARY_FONT,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontFamily: SECONDARY_FONT,
    fontWeight: 700,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  body1: {
    fontFamily: PRIMARY_FONT,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal'
  },
  body2: {
    fontFamily: SECONDARY_FONT,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  nav: {
    fontFamily: SECONDARY_FONT,
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.22,
    letterSpacing: 'normal'
  },
  caption: {
    fontFamily: PRIMARY_FONT,
    fontSize: '12px',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal'

  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    // textTransform: 'uppercase'
  },
  username: {
    fontFamily: SECONDARY_FONT,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal'
  },
  dialog: {
    fontFamily: SECONDARY_FONT,
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: '-0.58px'
  },
} as const

export default typography
